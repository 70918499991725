import React from "react";
import Typography from "@mui/material/Typography";

function ResultsFooter() {
  return (
    <>
      <Typography paragraph={true}>
        If you need more information or help regarding your home, please call us
        on 0330 088 2050
      </Typography>
    </>
  );
}

export default ResultsFooter;
